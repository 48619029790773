






























































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aActividades: Array<any> = [];
  private nombreCategoria: any = "";
  private currentPage = 1;
  private total = 0;
  private win = window;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getInfoActividadesCategoria();
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoActividadesCategoria();
      this.linkCanonical(this.nombreWeb);
    }
  }

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href =
      "https://muniarica.cl/actividades/categoria/" + nombreWeb;
    document.head.appendChild(canonicalLink);
  }

  private getInfoActividadesCategoria() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "actividades/categoria?pagina=" +
          this.currentPage +
          "&nombre_web=" +
          this.nombreWeb
      )
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aActividades = res.data.actividades;
        this.nombreCategoria = res.data.nombre;
        for (let i = 0; i < this.aActividades.length; i++) {
          if (this.aActividades[i].foto) {
            this.aActividades[i].foto = base + this.aActividades[i].foto;
          } else {
            this.aActividades[
              i
            ].foto = require("@/assets/logos/logoarica-color.webp");
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }

  private irCategoriaActividad(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CategoriaActividad" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CategoriaActividad",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
